/* eslint-disable no-nested-ternary */
import { ReactElement } from 'react';
import { isNaN } from 'lodash';
import * as S from './NumericScore.styles';
import { ScoreBar } from 'components/molecules/ScoreBar/ScoreBar';
import { UppercaseText } from 'components/atoms/UppercaseText/UppercaseText';

interface NumericScoreProps {
  assessorsAverage: number;
  selfLabel: string;
  assessorsLabel: string;
  selfAnswer?: string | number;
  selfAverageElement?: number;
  perElement?: boolean;
}

export const NumericScore = ({
  assessorsLabel,
  selfLabel,
  assessorsAverage,
  selfAnswer,
  selfAverageElement,
  perElement,
}: NumericScoreProps): ReactElement => {
  return (
    <S.StyledScoreBarFlex>
      <div>
        {perElement ? (
          isNaN(selfAverageElement) ? null : (
            <>
              <UppercaseText>{selfLabel}</UppercaseText>
              <ScoreBar score={selfAverageElement} />
            </>
          )
        ) : selfAnswer === -1 ? null : typeof selfAnswer === 'number' ? (
          <>
            <UppercaseText>{selfLabel}</UppercaseText>
            <ScoreBar score={selfAnswer} />
          </>
        ) : (
          <S.StyledParagraph color="cream">
            The participant hasn’t completed the assessment. You can always send them a friendly
            reminder.
          </S.StyledParagraph>
        )}
      </div>

      <div>
        <UppercaseText>{assessorsLabel}</UppercaseText>
        {typeof assessorsAverage === 'number' && !isNaN(assessorsAverage) ? (
          <ScoreBar score={assessorsAverage} />
        ) : (
          <S.StyledParagraph color="cream">
            There are still some assessors that haven’t completed the assessment. You can always
            send them a friendly reminder.
          </S.StyledParagraph>
        )}
      </div>
    </S.StyledScoreBarFlex>
  );
};
