import { ReactElement } from 'react';
import * as S from './Bar.styles';

interface IProps {
  score: number;
}

const Bar = ({ score }: IProps): ReactElement => {
  return (
    <S.Bar>
      <S.Dot highlight={score > 0} />
      <S.Dot highlight={score >= 25} />
      <S.Dot highlight={score >= 50} />
      <S.Dot highlight={score >= 75} />
      <S.Dot highlight={score === 100} />
      <S.Progress score={score} />
    </S.Bar>
  );
};

export default Bar;
