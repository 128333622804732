import styled from 'styled-components';
import { StyledHeading } from 'components/atoms/Heading/Heading.styles';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { StyledParagraph } from 'components/atoms/Paragraph/Paragraph.styles';
import { StyledTabs } from 'components/atoms/Tabs/Tabs.styles';

export const StyledDashboardContainer = styled.div`
  padding: 9rem 0 2rem;
  position: relative;
  z-index: 1;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: 2.6rem 0;
  }

  @media print {
    padding: 0;

    ${StyledHeading},
    ${StyledParagraph},
    ${StyledTabs} {
      display: none !important;
    }
  }
`;
