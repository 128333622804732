import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const Footer = styled.footer`
  padding: 3rem;
  border-top: 2px solid #bbbbbb;

  @media ${respondTo(MediaQuery.MIN_768)} {
    padding: 30px 60px;
  }

  @media print {
    display: none !important;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  @media ${respondTo(MediaQuery.MIN_768)} {
    flex-direction: row;
    align-items: center;
    margin-top: 0;
  }

  button {
    display: flex;
    gap: 6px;
    width: 150px;
  }

  button svg {
    max-width: 100%;
    height: auto;
  }
`;

export const TermsPolicyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    margin-top: 0;
  }
`;

export const TermsConditions = styled.a`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.color.greyA};
    transition: color 0.3s ease-in-out;
  }
`;

export const PrivacyPolicy = styled.a`
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: ${({ theme }) => theme.color.greyA};
    transition: color 0.3s ease-in-out;
  }
`;
