/* eslint-disable no-nested-ternary */
import { ReactElement } from 'react';
import { mean as average } from 'lodash';
import Bar from './Bar/Bar';
import * as S from './FeedbackPrint.styles';

import { Cohort, Participant } from 'services/types/DashboardTypes';
import { CohortParticipantResponse } from 'services/types/ParticipantDashboard';

interface IProps {
  participant: Participant;
  cohort: Cohort | undefined;
  selfNumericTitle: string;
  assessorsNumericTitle: string;
}

const FeedbackPrint = ({
  participant,
  cohort,
  selfNumericTitle,
  assessorsNumericTitle,
}: IProps): ReactElement => {
  return (
    <S.Feedback>
      <S.FrontPage>
        <S.StyledLogo />
        <S.FrontPageContent>
          <S.StyledLogoFull />
          <S.FrontPageTitle>
            <span>360</span> Mirror
          </S.FrontPageTitle>
          <S.FrontPageFooter>
            <div>{participant.name}</div>
            <div>
              {new Date(
                (cohort as unknown as CohortParticipantResponse).cohort_survey.start_date,
              ).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
              })}
            </div>
          </S.FrontPageFooter>
        </S.FrontPageContent>
      </S.FrontPage>
      <S.Section>
        <S.SeationHeader>
          <S.StyledTHNK></S.StyledTHNK>1
        </S.SeationHeader>
        <S.SeationBanner>
          <S.SectionTitle>The 360 mirror</S.SectionTitle>
          <p style={{ marginTop: '6pt' }}>
            “
            <i>
              It is the obvious which is so difficult to see most of the time. People say
              &apos;It&apos;s as plain as the nose on your face.&apos; But how much of the nose on
              your face can you see, unless someone holds a mirror up to you?
            </i>
            ” - Isaac Asimov, I Robot
          </p>
        </S.SeationBanner>
        <S.SeationContent>
          <p>
            Leadership development is a journey with no fixed destination. Just like an asymptote in
            geometry gets closer and closer to an axis but never reaches it, you can get closer to
            your destination and you will always have an opportunity to develop further. To get
            closer to that destination, you first need to know where you are now and what steps you
            want to take to get closer. This report tells you about your leadership qualities,
            strengths and your development potential, perceived by you and by others. Let&apos;s
            begin!
          </p>
          <S.SectionSubTitle style={{ marginTop: '12pt' }}>
            How to Read this Report
          </S.SectionSubTitle>
          <p style={{ marginTop: '12pt' }}>
            Every person has a unique set of gifts and opportunities. This assessment allows you to
            discover yours. Look for areas where your self-scoring significantly differs from how
            other people rated you and then consider why these ratings are different. For example,
            if others scored you more highly on a leadership quality than you scored yourself, then
            maybe you have a hidden gift. And conversely, if you rated yourself highly but others
            rated you less favorably, then you may have a blind spot. Start with the big picture,
            your score across the elements. Then zoom in on each leadership quality. At the end of
            this report you&apos;ll find a section to reflect on your strenghts and areas for
            development.
          </p>
        </S.SeationContent>
      </S.Section>
      <S.Section>
        <S.SeationHeader>
          <S.StyledTHNK></S.StyledTHNK>2
        </S.SeationHeader>
        <S.SeationBanner>
          <S.SectionTitle>Overall results - Leadership elements</S.SectionTitle>
        </S.SeationBanner>
        <S.SeationContent>
          {participant.assessment?.elements.map((element) => {
            if (
              !element.questions.some((question) => {
                return question.no_answer;
              })
            )
              return null;
            const selftAverage = Math.round(
              average(
                element.questions
                  .filter((question) => question.no_answer)
                  .map((question) => {
                    return question?.self_answer;
                  })
                  ?.filter((answer) => answer !== -1),
              ),
            );
            const assessorsAvegare = Math.round(
              average(
                element.questions
                  .filter((question) => question.no_answer)
                  .map((question) => {
                    return question?.assessor_answers;
                  })
                  ?.flat(1)
                  ?.filter((answer) => answer !== -1),
              ),
            );
            return (
              <S.QuestionContainer key={element.name}>
                <div>
                  <S.SectionSubTitle>{element.name}</S.SectionSubTitle>
                  {!element.description ? null : (
                    <div style={{ marginTop: '6pt' }}>{element.description}</div>
                  )}
                </div>
                <S.AnswerContainer>
                  {selftAverage === -1 ? null : (
                    <div>
                      <S.AnswerHeader>
                        <div>{selfNumericTitle}</div>
                        <S.Score>{selftAverage}</S.Score>
                      </S.AnswerHeader>
                      <Bar score={selftAverage} />
                    </div>
                  )}
                  <div>
                    <S.AnswerHeader>
                      <div>{assessorsNumericTitle}</div>
                      <S.Score>{assessorsAvegare}</S.Score>
                    </S.AnswerHeader>
                    <Bar score={assessorsAvegare} />
                  </div>
                </S.AnswerContainer>
              </S.QuestionContainer>
            );
          })}
        </S.SeationContent>
      </S.Section>
      <S.Section>
        <S.SeationHeader>
          <S.StyledTHNK></S.StyledTHNK>3
        </S.SeationHeader>
        <S.SeationBanner>
          <S.SectionTitle>Your full report</S.SectionTitle>
        </S.SeationBanner>
        <S.SeationContent>
          {participant.assessment?.elements.map((element) => {
            return (
              <S.SectionChunk key={element.name}>
                <S.ChunkTitle>{element.name}</S.ChunkTitle>
                {element.questions.map((question) => {
                  const openQuestion = !question.no_answer;
                  const assessorsAvegare = openQuestion
                    ? question.assessor_answers.map((answer) => (
                        <div key={question.title}>{answer}</div>
                      ))
                    : Math.round(
                        average(
                          question?.assessor_answers?.flat(1)?.filter((answer) => {
                            return answer !== -1;
                          }),
                        ),
                      );

                  return (
                    <S.QuestionContainer key={question.title}>
                      <div>
                        <S.SectionSubTitle>{question.title}</S.SectionSubTitle>
                        <div style={{ marginTop: '6pt' }}>{question.content}</div>
                      </div>
                      <S.AnswerContainer>
                        {openQuestion ? (
                          <div>
                            <S.AnswerHeader>
                              <div>Your response</div>
                            </S.AnswerHeader>
                            <S.AnswerWrapper>{question.self_answer}</S.AnswerWrapper>
                          </div>
                        ) : question.self_answer === -1 ? null : (
                          <div>
                            <S.AnswerHeader>
                              <div>{selfNumericTitle}</div>
                              <S.Score>{question.self_answer}</S.Score>
                            </S.AnswerHeader>
                            <Bar score={question.self_answer as number} />
                          </div>
                        )}

                        <div>
                          <S.AnswerHeader>
                            {openQuestion ? (
                              <div>Responses from others</div>
                            ) : (
                              <div>{assessorsNumericTitle}</div>
                            )}
                            {openQuestion ? null : <S.Score>{assessorsAvegare}</S.Score>}
                          </S.AnswerHeader>
                          {openQuestion ? (
                            <div style={{ marginTop: '6pt' }}>
                              {question.assessor_answers.map((answer, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <S.AnswerWrapper key={`${answer}${index}`}>
                                  <S.OpenAnswer>{answer}</S.OpenAnswer>
                                </S.AnswerWrapper>
                              ))}
                            </div>
                          ) : (
                            <Bar score={assessorsAvegare as number} />
                          )}
                        </div>
                      </S.AnswerContainer>
                    </S.QuestionContainer>
                  );
                })}
              </S.SectionChunk>
            );
          })}
        </S.SeationContent>
      </S.Section>
      <S.Section>
        <S.SeationHeader>
          <S.StyledTHNK></S.StyledTHNK>4
        </S.SeationHeader>
        <S.SeationBanner>
          <S.SectionTitle>Your reflection</S.SectionTitle>
        </S.SeationBanner>
        <S.SeationContent>
          <p>
            {' '}
            You are looking at your 360 Mirror. Looking in a mirror means looking at yourself. What
            do you see? And how do others see you? This assessment tells you about your leadership
            qualities, perceived by yourself and by others. It shows your strengths and development
            potential, or gifts and learning opportunities. This assessment will help you to explore
            and further develop your leadership.
          </p>
          <ol style={{ listStyle: 'auto', listStylePosition: 'inside', marginTop: '12pt' }}>
            <li>
              Looking at your results, select three areas where the ratings or comments pleased you.
              Write these areas below and reflect on why each one pleased you.
            </li>
            <li>
              Looking at those three areas that pleased you, what conclusions can you draw about
              your strengths?{' '}
            </li>
            <li>
              What skills or qualities can you lean into more in your leadership to leverage your
              strengths?{' '}
            </li>
            <li>
              Now look back at your results and identify three areas where the ratings or comments
              displeased you. Write these areas below and reflect on why each one displeased you.{' '}
            </li>
            <li>
              Looking at these three areas, what insights can you draw about your areas for growth?{' '}
            </li>
            <li>
              Are there any concrete things you&apos;d like to do differently moving forward?{' '}
            </li>
            <li>What specific objectives do you want to set for yourself moving forward? </li>
            <li>Who can help hold you accountable to these objectives? </li>
          </ol>
        </S.SeationContent>
      </S.Section>
      <S.Footer>
        <S.StyledMonochrome />
        <S.Quote>Knowing yourself is the beginning of all wisdom.</S.Quote>
        <S.Author>Aristotele</S.Author>
      </S.Footer>
    </S.Feedback>
  );
};

export default FeedbackPrint;
