import { ReactElement, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './ParticipantFeedback.styles';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Separator } from 'components/atoms/Separator/Separator';
import { CardTabs } from 'components/atoms/CardTabs/CardTabs';
import { TabsContainer } from 'components/molecules/TabsContainer/TabsContainer';
import { slideFade } from 'util/motionTransitions';
import { ActiveFeedbackContent } from 'components/organisms/ActiveFeedbackContent/ActiveFeedbackContent';
import { EmptyFeedbackCard } from 'components/molecules/EmptyFeedbackCard/EmptyFeedbackCard';
import { EmptyDashboard } from 'components/molecules/EmptyDashboard/EmptyDashboard';
import { RootState } from 'redux/store';
import {
  selectAreEnoughAssessors,
  selectCurrentParticipant,
  assessmentIsSelfAnswered,
  selectCurrentCohort,
} from 'redux/ducks/dashboardDuck/dashboardReducer';
import { useCurrentUserIndex } from 'hooks/useCurrentUserIndex';
import FeedbackPrint from 'components/molecules/FeedbackPrint/FeedbackPrint';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ParticipantFeedbackProps {}

// eslint-disable-next-line no-empty-pattern
export const ParticipantFeedback = ({}: ParticipantFeedbackProps): ReactElement => {
  const { cohort, isSelfAnswered, participant, areEnoughAssessors, currentCohortIndex } =
    useSelector((state: RootState) => ({
      participant: selectCurrentParticipant(state.dashboard),
      cohort: selectCurrentCohort(state.dashboard),
      areEnoughAssessors: selectAreEnoughAssessors(state.dashboard),
      isSelfAnswered: assessmentIsSelfAnswered(state.dashboard),
      currentCohortIndex: state.dashboard.currentCohortIndex,
    }));
  const [activeElement, setActiveElement] = useState(0);
  const intl = useIntl();
  const dispatch = useDispatch();
  useCurrentUserIndex(true);

  const tabs = useMemo(
    () => participant?.assessment?.elements?.map(({ name }) => name || '') || [],
    [participant?.assessment],
  );

  useEffect(() => {
    setActiveElement(0);
  }, [dispatch, currentCohortIndex]);

  if (!participant?.assessment || !isSelfAnswered)
    return <EmptyDashboard cohortSurveyId={cohort?.cohort_survey.id} assessmentIsAvailable />;

  if (!areEnoughAssessors) return <EmptyFeedbackCard cohortSurveyId={cohort?.cohort_survey.id} />;

  return (
    <>
      <S.StyledCardContainer {...slideFade()} variant="blue">
        <Heading type={HeadingType.H6} margin="0 0 1.8rem">
          <FormattedMessage
            defaultMessage="Feedback Results"
            description="Dashboard - Feedback Card - Title"
          />
        </Heading>
        <TabsContainer activeTab={activeElement} setActiveTab={setActiveElement}>
          <>
            <CardTabs gap="3.9rem" tabs={tabs} />
            <Separator color="greyE" margin="3.2rem 0 1.7rem" />
          </>
          {(activetab: number) => (
            <ActiveFeedbackContent
              questions={
                participant?.assessment?.elements
                  ? participant?.assessment?.elements[activetab]?.questions || []
                  : []
              }
              selfNumericTitle={intl.formatMessage({
                defaultMessage: 'How you scored yourself',
                description: 'Dashboard - Feedback Card - Scorebar Label 1',
              })}
              assessorsNumericTitle={intl.formatMessage({
                defaultMessage: 'How others scored you',
                description: 'Dashboard - Feedback Card - Scorebar Label 2',
              })}
              activeElement={participant?.assessment?.elements[activetab]}
            />
          )}
        </TabsContainer>
      </S.StyledCardContainer>
      <FeedbackPrint
        participant={participant}
        cohort={cohort}
        selfNumericTitle={intl.formatMessage({
          defaultMessage: 'How you scored yourself',
          description: 'Dashboard - Feedback Card - Scorebar Label 1',
        })}
        assessorsNumericTitle={intl.formatMessage({
          defaultMessage: 'How others scored you',
          description: 'Dashboard - Feedback Card - Scorebar Label 2',
        })}
      />
    </>
  );
};
