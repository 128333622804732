import styled from 'styled-components';
import { theme } from 'styles/theme/default';

export const Bar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 15pt 0;
  height: 3pt;
  border-radius: 24pt;
  background-color: ${theme.newColor.primary90};
`;

export const Dot = styled.div<{ highlight?: boolean }>`
  height: 9pt;
  width: 9pt;
  border-radius: 50%;
  background-color: ${({ highlight }) =>
    highlight ? theme.newColor.primary100 : theme.newColor.primary90};
`;

export const Progress = styled.div<{ score: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ score }) => `${score}%`};
  height: 100%;
  border-radius: 24pt;
  background-color: ${theme.newColor.primary100};
`;
