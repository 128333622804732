/* eslint-disable import/no-unresolved */
/* eslint-disable max-lines */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import { ReactElement, useEffect, useMemo, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import { AssessmentLocationState } from 'globalTypes';
import { Rate } from './components/Rate/Rate';
import * as S from './Assessment.styles';
import { userRoles } from 'model/User';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Separator } from 'components/atoms/Separator/Separator';
import { TextArea } from 'components/atoms/TextArea/TextArea';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { Form } from 'components/molecules/Form/Form';
import { StickyBar } from 'components/atoms/StickyBar/StickyBar';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { ScrollDownArrow } from 'components/atoms/ScrollDownArrow/ScrollDownArrow';
import { MediaQuery } from 'styles/mediaQuery';
import { Hide } from 'components/atoms/Hide/Hide';
import { Path } from 'routes/Path';
import { useAssesssmentForm } from 'hooks/useAssessmentForm';
import { QuestionProps } from 'redux/ducks/questionsDuck/questionsTypes';
import { Loader } from 'components/atoms/Loading/Loading.styles';
import { StorageItem } from 'data/enum/StorageItem';
import { eases } from 'util/motionTransitions';
import { setErrorEvent } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { AssessmentPostProps } from 'redux/ducks/assessmentsDuck/assessmentsTypes';
import { SurveyService } from 'services/SurveyService';
import { AssessmentService } from 'services/AssessmentService';
import { getUserRole } from 'util/getUserRole';
import { CohortSurvey } from 'services/types/SurveyTypes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AssessmentProps {}

export const Assessment = ({ ...props }: AssessmentProps): ReactElement => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { push } = useHistory();
  const { values, setRateValue, handleInputChange } = useAssesssmentForm({});
  const { cohortSurveyId } = useParams<AssessmentLocationState>();

  const [temporaryData, setTemporaryData] = useState(
    JSON.parse(localStorage.getItem(StorageItem.TEMPORARY_SURVEY) || '{}'),
  );
  const [questions, setQuestions] = useState<Array<QuestionProps>>([]);
  const [currentSurvey, setCurrentSurvey] = useState<CohortSurvey>();
  const [pageTitle, setPageTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  // this is already predefined by API
  const questionLevelMapping: { [key: number]: number } = { 0: 5, 1: 7, 2: 10 };

  const animationProps = {
    show: (delay?: number) => ({
      opacity: 1,
      x: 0,
      transition: { delay: delay || 0, duration: 1, ease: eases.outExpo },
    }),
    hide: { opacity: 0, transition: { duration: 0.5, ease: eases.outExpo } },
    enter: { opacity: 0, x: '10%' },
  };

  const formatQuestion = (
    questionKey: string,
    questionValue: string | number,
    index: number,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    element: any,
  ) => {
    const questionId = +questionKey.split('-')[1];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const question = element.questions.find((question: any) => question.element_id === questionId);

    if (!question) return undefined;

    return {
      survey_element_id: question.survey_element_id,
      value: questionValue.toString(),
      no_answer: !question.set_as_open,
      order: index,
    };
  };

  const getFinalBody = () => {
    const assessment = {
      cohort_survey_id: cohortSurveyId,
      status: null,
    };
    const elements = currentSurvey?.elements.map((element, index) => {
      return {
        parent_survey_element_id: element.survey_element_id,
        order: index,
        questions: Object.entries(values)
          .map(([quesitonId, questionValue], index) =>
            formatQuestion(quesitonId, questionValue, index, element),
          )
          .filter((element) => element !== undefined),
      };
    });

    return {
      assessment,
      elements,
    };
  };

  const handleExitWithoutSaving = () => {
    localStorage.removeItem(StorageItem.TEMPORARY_SURVEY);
    push(Path.Dashboard);
  };

  const handleSaveExit = async () => {
    const user = await getUserRole();
    const finalBody = getFinalBody();
    if (!finalBody) return;

    if (
      finalBody.elements?.some((element) => {
        return element.questions.some((question) => {
          return !question?.no_answer && !question?.value;
        });
      })
    ) {
      dispatch(setErrorEvent(Events.OPEN_QUESTIONS_ERROR));
      return;
    }

    try {
      const assessments = await AssessmentService.createAssessment(
        finalBody as unknown as AssessmentPostProps,
      );

      const selfAssessment = assessments.find((assessment) => !assessment.assessor_id);

      localStorage.removeItem(StorageItem.TEMPORARY_SURVEY);

      if (user?.role === userRoles.assessor) {
        push(`${Path.AssessmentCompleted}?assessor=true`, {
          name: currentSurvey?.participant_name || '',
        });
      } else {
        push(Path.AssessmentFeedback, {
          assessment_id: selfAssessment?.id,
        });
      }
    } catch (error) {
      dispatch(setErrorEvent(Events.FORM_REQUEST_ERROR));
    }
  };

  useEffect(() => {
    // Update the temporary local storage when page or values change
    const localStorageData = {
      values: {
        ...temporaryData.values,
        ...values,
      },
    };

    setTemporaryData(localStorageData);
    localStorage.setItem(StorageItem.TEMPORARY_SURVEY, JSON.stringify(localStorageData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (!currentSurvey) return;
    const questionArrayInElements = currentSurvey.elements.map((element) =>
      element.questions.map((question: QuestionProps) => ({
        ...question,
        parent_element_id: element.element_id,
      })),
    );

    if (currentSurvey.participant_name === undefined) {
      setPageTitle(
        intl.formatMessage({
          description: 'Assessment - Title if participant',
          defaultMessage: 'Rate yourself on the following skills',
        }),
      );
    } else {
      setPageTitle(
        intl.formatMessage(
          {
            description: 'Assessment - Title if assesor',
            defaultMessage: 'Rate {name} on the following skills',
          },
          {
            name: currentSurvey.participant_name,
          },
        ),
      );
    }

    const flattenQuestions = questionArrayInElements.flatMap((item) => item);

    const closedQuestions = flattenQuestions.filter((item) => !item.set_as_open);
    const openQuestions = flattenQuestions.filter((item) => item.set_as_open);

    setQuestions([...closedQuestions, ...openQuestions]);
  }, [currentSurvey, intl]);

  useEffect(() => {
    const fetchSurvey = async () => {
      if (!cohortSurveyId) return;

      try {
        const newSurvey = await SurveyService.getSurvey(cohortSurveyId);

        setCurrentSurvey(newSurvey);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    };
    fetchSurvey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohortSurveyId]);

  if (!questions) {
    return <Loader color="blueA" />;
  }

  return (
    <NormalLayout padding="normal" background={['#F2F3F5']}>
      <S.StyledAssessment {...props}>
        {!errorMessage ? (
          <>
            <Heading
              type={HeadingType.H2}
              margin={['0 0 3rem', [MediaQuery.MIN_768, '0 0 6.7rem']]}
            >
              {pageTitle}
            </Heading>
            <Form>
              <AnimatePresence>
                {questions &&
                  questions.map((item, index) => (
                    <S.StyledQuestion
                      variants={animationProps}
                      animate="show"
                      exit="hide"
                      custom={index * 0.05}
                      initial="enter"
                      key={`skill-${index}`}
                    >
                      {!item.set_as_open ? (
                        <Rate
                          value={
                            temporaryData.values &&
                            temporaryData.values[`question-${item.element_id}`]
                          }
                          title={`question-${item.element_id}`}
                          name={item.name}
                          description={item.description}
                          setRateValue={setRateValue}
                          dots={questionLevelMapping[currentSurvey?.question_level || 0]}
                        />
                      ) : (
                        <>
                          <Heading type={HeadingType.H4}>{item.name}</Heading>
                          {item.description && (
                            <Paragraph
                              margin={['1rem 0 0', [MediaQuery.MIN_768, '1.7rem 0 0']]}
                              color="currentColor"
                            >
                              {item.description}
                            </Paragraph>
                          )}
                          <RegisterFormItem
                            register
                            registerErrors="required"
                            name={`question-${item.element_id}`}
                            size={FormItemSizes.Medium}
                          >
                            <TextArea
                              defaultValue={
                                temporaryData.values &&
                                temporaryData.values[`question-${item.element_id}`]
                              }
                              hasRef
                              handleInputChange={handleInputChange}
                              maxLength={2500}
                            />
                          </RegisterFormItem>
                        </>
                      )}
                      {index !== questions.length - 1 && (
                        <Separator margin={['3rem 0', [MediaQuery.MIN_768, '5.6rem 0']]} />
                      )}
                    </S.StyledQuestion>
                  ))}
              </AnimatePresence>

              <StickyBar>
                <Flex container gap="3rem" alignItems="center" justifyContent="flex-end">
                  <Flex
                    container
                    gap="3rem"
                    alignItems="center"
                    justifyContent="flex-end"
                    flexWrap="wrap"
                  >
                    <SecondaryButton type="button" onClick={handleExitWithoutSaving} color="greyA">
                      <span>
                        <FormattedMessage
                          description="Assessment - Exit Without Save Copy"
                          defaultMessage="Exit without saving"
                        />
                      </span>
                    </SecondaryButton>
                    <PrimaryButton type="button" onClick={handleSaveExit} $size="regular">
                      <FormattedMessage
                        description="Assessment - Submit Copy"
                        defaultMessage="Submit"
                      />
                    </PrimaryButton>
                  </Flex>
                </Flex>
              </StickyBar>
            </Form>
          </>
        ) : (
          <>
            <Heading type={HeadingType.H3} margin={['0 0 1rem', [MediaQuery.MIN_768, '0 0 2rem']]}>
              You&apos;ve already completed the assessment.
            </Heading>
            <Heading type={HeadingType.H4}>
              Thank you for your feedback. If you have any questions, please contact us at{' '}
              <S.StyledLink href="mailto:support@thnk.org" target="_blank" rel="noreferrer">
                support@thnk.org
              </S.StyledLink>
              .
            </Heading>
          </>
        )}
      </S.StyledAssessment>
    </NormalLayout>
  );
};
