import styled from 'styled-components';
import { ReactComponent as Logo } from '../../../assets/img/logo/logo.svg';
import { ReactComponent as LogoFull } from '../../../assets/img/logo/logo-full.svg';
import { ReactComponent as THNK } from '../../../assets/img/logo/thnk.svg';
import { ReactComponent as Monochrome } from '../../../assets/img/logo/thnk-full-monochrome.svg';
import { theme } from 'styles/theme/default';

export const Feedback = styled.div`
  display: none;
  font-size: 10.5pt;
  line-height: 1.5;
  letter-spacing: -0.05em;

  @media print {
    display: block;
  }
`;

export const FrontPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45pt;
  width: 100%;
  height: 100vh;
  page-break-after: always;
  background-color: ${theme.newColor.primary100};
  color: ${theme.newColor.textDark};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6pt;
  width: 100%;
  height: 100vh;
  padding: 30pt;
  page-break-after: auto;
  background-color: ${theme.newColor.primary100};
  color: ${theme.color.white};
`;

export const FrontPageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 30pt;
  flex-grow: 1;
  margin: 30pt;
  padding: 30pt 18pt;
  background-color: ${theme.color.white};
`;

export const StyledLogo = styled(Logo)`
  width: 240pt;
  height: 240pt;
  opacity: 0.5;
  margin-left: auto;
`;

export const StyledLogoFull = styled(LogoFull)`
  width: 111pt;
  height: 60pt;
`;

export const StyledTHNK = styled(THNK)`
  width: 60pt;
  height: 12pt;
`;

export const StyledMonochrome = styled(Monochrome)`
  width: 111pt;
  height: 60pt;
`;

export const FrontPageTitle = styled.h1`
  font-size: 48pt;
  line-height: 1.15;
  font-weight: 600;

  span {
    color: ${theme.newColor.secondary};
  }
`;

export const FrontPageFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30pt;
  font-size: 15pt;
  line-height: 1.5;
  font-weight: 500;
`;

export const Section = styled.div`
  page-break-after: always;
`;

export const SeationHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20pt;
  padding: 18pt;
  background-color: ${theme.newColor.surface100};
`;

export const SeationBanner = styled.div`
  padding: 18pt;
  background-color: ${theme.newColor.primary100};
  color: ${theme.color.white};
`;

export const SectionTitle = styled.h2`
  font-size: 24pt;
  line-height: 1.25;
  font-weight: 600;
`;

export const SectionSubTitle = styled.h2`
  font-size: 15pt;
  line-height: 1.25;
  font-weight: 600;
`;

export const SeationContent = styled.div`
  padding: 18pt;
`;

export const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18pt;
  flex: 0 0 calc((100% - 30pt) / 2);
`;

export const QuestionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30pt;

  &:not(:first-of-type) {
    padding-top: 12pt;
    margin-top: 18pt;
    border-top: 0.75pt solid ${theme.newColor.border300};
  }
`;

export const AnswerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6pt;
  font-weight: 600;
`;

export const Score = styled.div`
  padding: 1.5pt 3pt;
  font-weight: 500;
  background-color: ${theme.newColor.surface100};
`;

export const SectionChunk = styled.div`
  page-break-after: always;

  &:not(:first-of-type) {
    margin-top: 30pt;
  }
`;

export const ChunkTitle = styled.h3`
  display: inline-block;
  font-size: 18pt;
  line-height: 1.25;
  font-weight: 600;
  padding-bottom: 6pt;
  margin-bottom: 18pt;
  border-bottom: 2.25pt solid ${theme.newColor.secondary};
`;

export const AnswerWrapper = styled.div`
  padding-top: 6pt;
  page-break-inside: 'avoid';
`;

export const OpenAnswer = styled.div`
  padding: 3pt 6pt;
  font-weight: 500;
  background-color: ${theme.newColor.surface100};
`;

export const Quote = styled.blockquote`
  position: relative;
  font-size: 48pt;
  line-height: 1.15;
  font-weight: 600;
  margin: auto 0 0;

  &::before {
    content: '“';
    position: absolute;
    bottom: calc(100% + 6pt);
    left: 0;
    font-size: 60pt;
    line-height: 0;
    opacity: 0.5;
    font-family: 'auto';
  }
`;

export const Author = styled.div`
  font-size: 18pt;
`;
