/* eslint-disable no-console */
// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild } from 'globalTypes';
import {
  ChangeEvent,
  ChangeEventHandler,
  DOMAttributes,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import * as S from './TextArea.styles';

export interface TextAreaProps extends RegisterFormItemChild, DOMAttributes<HTMLDivElement> {
  maxLength?: number;
  defaultValue?: string;
  placeholder?: string;
  handleInputChange?: ChangeEventHandler<HTMLTextAreaElement>;
  isDisabled?: boolean;
}

export const TextArea = forwardRef(
  (
    {
      name,
      maxLength,
      input,
      defaultValue,
      placeholder,
      hasError,
      formMethods,
      onChange,
      handleInputChange,
      isDisabled,
      ...props
    }: TextAreaProps,
    ref,
  ): ReactElement => {
    useEffect(() => {
      if (formMethods && defaultValue && name) {
        formMethods.setValue(name, defaultValue);
      }
      if (handleInputChange) {
        handleInputChange({
          target: { value: defaultValue || '', name } as EventTarget & HTMLTextAreaElement,
        } as ChangeEvent<HTMLTextAreaElement>);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [characterCount, setCharacterCount] = useState(0);

    const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setCharacterCount(event.target.value.length);

      if (handleInputChange) {
        handleInputChange(event);
      }
      if (input && input.onChange) {
        input.onChange(event);
      }
    };

    return (
      <S.StyledTextAreaContainer {...props}>
        <S.StyledTextArea
          ref={ref as ForwardedRef<HTMLTextAreaElement>}
          maxLength={maxLength}
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          hasError={hasError}
          disabled={isDisabled}
          onChange={handleOnChange}
        />
        {maxLength && (
          <S.StyledTextAreaNote>
            {characterCount}/{maxLength}
          </S.StyledTextAreaNote>
        )}
      </S.StyledTextAreaContainer>
    );
  },
);
