// eslint-disable-next-line import/no-unresolved
import { TabsProps } from 'globalTypes';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { PillTabs } from 'components/atoms/PillTabs/PillTabs';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { RootState } from 'redux/store';
import {
  selectAreEnoughAssessors,
  selectCurrentParticipant,
} from 'redux/ducks/dashboardDuck/dashboardReducer';
import { Download } from 'components/atoms/Icon/Icon';
import { StyledSecondaryButton } from 'components/molecules/DownloadButton/DownloadButton.styles';

interface StatsHeaderProps extends Omit<TabsProps, 'tabs'> {
  isUserDashboard?: boolean;
  tabs?: Array<string>;
}

export const StatsHeader = ({
  tabs,
  isUserDashboard,
  active = 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActive = () => {},
}: StatsHeaderProps): ReactElement => {
  const { areEnoughAssessors } = useSelector((state: RootState) => ({
    participant: selectCurrentParticipant(state.dashboard),
    areEnoughAssessors: selectAreEnoughAssessors(state.dashboard),
  }));

  return (
    <>
      {isUserDashboard && tabs && tabs.length > 0 && (
        <PillTabs tabs={tabs} active={active} setActive={setActive} />
      )}

      {areEnoughAssessors && isUserDashboard && active === 0 && (
        <Flex container flexWrap="wrap" gap="1rem 3rem">
          <StyledSecondaryButton
            onClick={() => {
              window.print();
            }}
            type="button"
            color="blueA"
          >
            <FormattedMessage
              defaultMessage="Export"
              description="User Dashboard - Assessment - Export"
            />
            <Download />
          </StyledSecondaryButton>
        </Flex>
      )}
    </>
  );
};
